import React from "react";
import {
  useDisclosure,
  Button,
  Box,
  Image,
  Icon,
  Link,
  // Editable,
  // EditableInput,
  // EditablePreview,
  // CircularProgress,
  // CircularProgressLabel,
  Divider,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  // HStack,
  Text,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
// import background from "./images/mg-bckg-01-1280w.png";
// import background from "./images/mg-bckg-01-400w.png";
// import background from "./images/mg-bckg-01-600w.png";
// import background from "./images/mg-bckg-01-800w.png";
// import background from "./images/mg-bckg-02-1280w.png";
// import background from "./images/mg-bckg-02-btp-1280w.png";
// import background from "./images/mg-bckg-02-btp-1280x720.png";
// import background from "./images/mg-bckg-02-btp-1280x800.png";
// import background from "./images/mg-bckg-02-ptb-1280w.png";
// import background from "./images/mg-bckg-02-ptb-1280x720.png";
// import background from "./images/mg-bckg-02-ptb-1280x800.png";
// import background from "./images/mg-bckg-02r-1280w.png";
// import background from "./images/mg-bckg-03-1280w.png";
// import background from "./images/mg-bckg-03-btp-1280w.png";
// import background from "./images/mg-bckg-03-btp-1280x720.png";
// import background from "./images/mg-bckg-03-ptb-1280w.png";
// import background from "./images/mg-bckg-03-ptb-1280x720.png";
// import background from "./images/mg-bckg-03r-1280w.png";
// import background from "./images/mg-bckg-04-1280w.png";
// import background from "./images/mg-bckg-04-btp-1280w.png";
// import background from "./images/mg-bckg-04-btp-1280x720.png";
// import background from "./images/mg-bckg-04-ptb-1280w.png";
// import background from "./images/mg-bckg-04-ptb-1280x720.png";
// import background from "./images/mg-bckg-04r-1280w.png";
import background from "./images/mg-bckg-05-1280w.png";
// import background from "./images/mg-bckg-06-1280w.png";
// import background from "./images/mg-bckg-06-b1-1280w.png";
// import background from "./images/mg-bckg-06-b2-1280w.png";
// import background from "./images/mg-bckg-06c-1280w.png";
// import background from "./images/mg-bckg-06c-btp-1280w.png";
// import background from "./images/mg-bckg-06c-btp-1280x720.png";
// import background from "./images/mg-bckg-06cr-1280w.png";
// import background from "./images/mg-bckg-07-1280w.png";
// import background from "./images/mg-bckg-08-1280w.png";
// import background from "./images/mg-bckg-09-1280w.png";
// import background from "./images/mg-bckg-09-600w.png";
// import background from "./images/mg-bckg-09a-1280w.png";
// import background from "./images/mg-bckg-09b-1280w.png";
// import background from "./images/mg-bckg-09c-1280w.png";
// import background from "./images/mg-flag-01-200w.png";
// import background from "./images/mg-flag-01.svg";
// import background from "./images/mg-gear-logo-200w.png";
// import background from "./images/mg-gear-logo.svg";
// import background from "./images/mg-hero-01-200w.png";
// import background from "./images/mg-hero-02-200w.png";
// import background from "./images/mg-hero-03-200w.png";
// import background from "./images/mg-hero-04-1000sq.png";
// import background from "./images/mg-hero-04-1200w.png";
// import background from "./images/mg-hero-04-800sq.png";
// import background from "./images/mg-hero-05-200w.png";
// import background from "./images/mg-hero-05-300w.png";
// import background from "./images/mg-hero-05-800w.png";
// import background from "./images/mg-hero-06-1200w.png";
// import background from "./images/mg-hero-07-1200w.png";
// import background from "./images/mg-hero-08-1200w.png";
// import background from "./images/mg-hero-09-1200w.png";
// import background from "./images/mg-hero-10-1200w.png";
// import background from "./images/mg-hero-10-200sq.png";
// import background from "./images/mg-hero-10-200w.png";
// import background from "./images/mg-icon-01-200w.png";
// import background from "./images/mg-icon-02-200w.png";
// import background from "./images/mg-icon-03-200w.png";
// import background from "./images/mg-icon-04-200w.png";
// import background from "./images/mg-icon-04a-200w.png";
// import background from "./images/mg-icon-05-200w.png";
// import background from "./images/mg-icon-06-200w.png";
// import background from "./images/mg-logo-01-200w.png";
// import background from "./images/mg-logo-02-200w.png";
// import background from "./images/mg-profile-2022-200w.png";
// import background from "./images/mg-profile-players-200w.png";
// import background from "./images/mg-bckg-12-1280w.png";
// import background from "./images/mg-bckg-12-1280x360.png";
// import background from "./images/mg-bckg-12-1280x1440.png";

import {
  FaRegGrinAlt,
  FaRegGrinBeamSweat,
  FaRegGrinBeam,
  FaRegGrinHearts,
  FaRegGrinStars,
  FaRegGrinWink,
} from "react-icons/fa";

function Drawer3() {
  const [size, setSize] = React.useState("lg");
  // const [step, setStep] = React.useState("GigNo:");
  const [step] = React.useState("GigNo:");
  // const [iconGigStep] = React.useState("GigStepNo:");


  const { isOpen, onOpen, onClose } = useDisclosure();
  const handleClick = (newSize) => {
    setSize(newSize);
    onOpen();
  };
  // const handleClick1 = (newStep) => {
  //   setStep(newStep);
  //   onOpen();
  // };
  // const sizes = ["xl", "xl", "lg", "lg", "md", "sm"];
  const steps = ["One", "Two", "Three", "Four", "Five", "Six"];
  // const iconGigSteps = ["FaRegGrinWink", "FaRegGrinBeamSweat","FaRegGrinBeam", "FaRegGrinAlt", "FaRegGrinHearts", "FaRegGrinStars"];

  return (
    <div style={{ backgroundImage: `url(${background})` }}>
      <Divider marginTop="5" />
      <Wrap spacing="30px" ml="100px" marginTop="5">
        <WrapItem width={{ base: "100%", sm: "85%", md: "80%", lg: "75%" }}>
          <Box w="100%">
            <Box borderRadius="lg" overflow="hidden">
              <Link textDecoration="none" _hover={{ textDecoration: "none" }}>
                <Image
                  transform="scale(1.0)"
                  // src={" https://bafybeignubrsxqj4tzdgz5mqxf545r2fk77e5qhbgk67aacqys2dsufdsq.ipfs.infura-ipfs.io/ "
                  // src={" https://bafybeia5uao63ppanvkyj7uxnwhmuxazrhnnrmzanblxfjlxkbozi3z57e.ipfs.infura-ipfs.io/ "
                  src={
                    " https://bafybeihyswfympur6dq4qswdfq5kwb6e5a2wmi4t4gvxbmxksxhkyc4mhy.ipfs.infura-ipfs.io/ "
                    // src={" https://bafybeigemdjp2j24wyqljbwc7nisg5l2m4h3r5ob5sa4eq4quml4egl3ae.ipfs.infura-ipfs.io/ "
                  }
                  alt=" MetaGame "
                  objectFit="contain"
                  width="100%"
                  transition="0.3s ease-in-out"
                  _hover={{
                    transform: "scale(1.05)",
                  }}
                />
              </Link>
            </Box>
          </Box>
        </WrapItem>
      </Wrap>

      <Box p={4} display={{ md: "flex" }}>
        <Box mt={{ base: 4, md: 0 }} ml={{ md: 12 }}>
          <Link
            mt={"30px"}
            color="gray.100"
            display="block"
            fontSize="3xl"
            lineHeight="normal"
            fontWeight="semibold"
            href=" # "
          >
            MetaCollab Gigs require just a few steps.
          </Link>
          <div>
            <Icon
              as={FaRegGrinWink}
              ml={"10"}
              mr={"70px"}
              p={2}
              w={20}
              h={20}
              color="lime"
            />
            <Icon
              as={FaRegGrinBeamSweat}
              mr={"70px"}
              p={2}
              w={20}
              h={20}
              color="yellow.400"
            />
            <Icon
              as={FaRegGrinBeam}
              mr={"70px"}
              p={2}
              w={20}
              h={20}
              color="lime"
            />
            <Icon
              as={FaRegGrinAlt}
              mr={"70px"}
              p={2}
              w={20}
              h={20}
              color="lime"
            />
            <Icon
              as={FaRegGrinHearts}
              mr={"70px"}
              p={2}
              w={20}
              h={20}
              color="lime"
            />
            <Icon
              as={FaRegGrinStars}
              mr={"70px"}
              p={2}
              w={20}
              h={20}
              color="lime"
            />
          </div>
          <br />


          {steps.map((step) => (
            <Button
              variant="outline"
              color="silver"
              onClick={() => handleClick(size)}
              key={size}
              m={2}
            >{`Gig Step ${step}`}</Button>
          ))}


          <Drawer onClose={onClose} isOpen={isOpen} size={size}>
            <DrawerOverlay />
            <DrawerContent>
              <DrawerHeader>{`${step} course contents`}</DrawerHeader>
              <DrawerBody>
                {size === "full"
                  ? `Captured 😆 , refresh the page to leave or press 'Esc' key.`
                  : null}
              </DrawerBody>
            </DrawerContent>
          </Drawer>









          <br />
          <Text mt={2} align="right" color="gray.300">
            MetaCollab is a feature of Web3Skills.
          </Text>
        </Box>
      </Box>
    </div>
  );
}
export default Drawer3;
