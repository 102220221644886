import {
  Container,
  SimpleGrid,
  Image,
  Flex,
  Heading,
  Text,
  Stack,
  StackDivider,
  Icon,
  useColorModeValue,
} from "@chakra-ui/react";
// import background from "./images/mg-bckg-01-1280w.png";
// import background from "./images/mg-bckg-01-400w.png";
// import background from "./images/mg-bckg-01-600w.png";
// import background from "./images/mg-bckg-01-800w.png";
// import background from "./images/mg-bckg-02-1280w.png";
// import background from "./images/mg-bckg-02-btp-1280w.png";
// import background from "./images/mg-bckg-02-btp-1280x720.png";
// import background from "./images/mg-bckg-02-btp-1280x800.png";
// import background from "./images/mg-bckg-02-ptb-1280w.png";
// import background from "./images/mg-bckg-02-ptb-1280x720.png";
// import background from "./images/mg-bckg-02-ptb-1280x800.png";
// import background from "./images/mg-bckg-02r-1280w.png";
// import background from "./images/mg-bckg-03-1280w.png";
// import background from "./images/mg-bckg-03-btp-1280w.png";
// import background from "./images/mg-bckg-03-btp-1280x720.png";
// import background from "./images/mg-bckg-03-ptb-1280w.png";
// import background from "./images/mg-bckg-03-ptb-1280x720.png";
// import background from "./images/mg-bckg-03r-1280w.png";
// import background from "./images/mg-bckg-04-1280w.png";
// import background from "./images/mg-bckg-04-btp-1280w.png";
// import background from "./images/mg-bckg-04-btp-1280x720.png";
// import background from "./images/mg-bckg-04-ptb-1280w.png";
// import background from "./images/mg-bckg-04-ptb-1280x720.png";
// import background from "./images/mg-bckg-04r-1280w.png";
// import background from "./images/mg-bckg-05-1280w.png";
// import background from "./images/mg-bckg-06-1280w.png";
// import background from "./images/mg-bckg-06-b1-1280w.png";
// import background from "./images/mg-bckg-06-b2-1280w.png";
import background from "./images/mg-bckg-06c-1280w.png";
// import background from "./images/mg-bckg-06c-btp-1280w.png";
// import background from "./images/mg-bckg-06c-btp-1280x720.png";
// import background from "./images/mg-bckg-06cr-1280w.png";
// import background from "./images/mg-bckg-07-1280w.png";
// import background from "./images/mg-bckg-08-1280w.png";
// import background from "./images/mg-bckg-09-1280w.png";
// import background from "./images/mg-bckg-09-600w.png";
// import background from "./images/mg-bckg-09a-1280w.png";
// import background from "./images/mg-bckg-09b-1280w.png";
// import background from "./images/mg-bckg-09c-1280w.png";
// import background from "./images/mg-flag-01-200w.png";
// import background from "./images/mg-flag-01.svg";
// import background from "./images/mg-gear-logo-200w.png";
// import background from "./images/mg-gear-logo.svg";
// import background from "./images/mg-hero-01-200w.png";
// import background from "./images/mg-hero-02-200w.png";
// import background from "./images/mg-hero-03-200w.png";
// import background from "./images/mg-hero-04-1000sq.png";
// import background from "./images/mg-hero-04-1200w.png";
// import background from "./images/mg-hero-04-800sq.png";
// import background from "./images/mg-hero-05-200w.png";
// import background from "./images/mg-hero-05-300w.png";
// import background from "./images/mg-hero-05-800w.png";
// import background from "./images/mg-hero-06-1200w.png";
// import background from "./images/mg-hero-07-1200w.png";
// import background from "./images/mg-hero-08-1200w.png";
// import background from "./images/mg-hero-09-1200w.png";
// import background from "./images/mg-hero-10-1200w.png";
// import background from "./images/mg-hero-10-200sq.png";
// import background from "./images/mg-hero-10-200w.png";
// import background from "./images/mg-icon-01-200w.png";
// import background from "./images/mg-icon-02-200w.png";
// import background from "./images/mg-icon-03-200w.png";
// import background from "./images/mg-icon-04-200w.png";
// import background from "./images/mg-icon-04a-200w.png";
// import background from "./images/mg-icon-05-200w.png";
// import background from "./images/mg-icon-06-200w.png";
// import background from "./images/mg-logo-01-200w.png";
// import background from "./images/mg-logo-02-200w.png";
// import background from "./images/mg-profile-2022-200w.png";
// import background from "./images/mg-profile-players-200w.png";
// import background from "./images/mg-bckg-12-1280w.png";
// import background from "./images/mg-bckg-12-1280x360.png";
// import background from "./images/mg-bckg-12-1280x1440.png";


import { AtSignIcon, SettingsIcon, SunIcon } from "@chakra-ui/icons";
import { ReactElement } from "react";
interface FeatureProps {
  text: string;
  iconBg: string;
  icon?: ReactElement;
}
const Feature = ({ text, icon, iconBg }: FeatureProps) => {
  return (
    <Stack direction={"row"} align={"center"}>
      <Flex
        w={8}
        h={8}
        align={"center"}
        justify={"center"}
        rounded={"full"}
        bg={iconBg}
      >
        {icon}
      </Flex>
      <Text fontWeight={600}>{text}</Text>
    </Stack>
  );
};
function Feature5() {
  return (
    <div style={{ backgroundImage: `url(${background})` }}>

    <Container maxW={"5xl"} py={12}>
      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
        <Stack spacing={4}>
          <Text
            textTransform={"uppercase"}
            color={"blue.900"}
            fontWeight={600}
            fontSize={"sm"}
            bg={useColorModeValue("gray.100", "blue.200")}
            p={2}
            alignSelf={"flex-start"}
            rounded={"md"}
          >
            Web3Skills Smart-Contracting Tools
          </Text>
          <Heading fontSize="3xl"> Collaborate together with Gigs. </Heading>
          <Text color={"gray.100"} fontSize={"lg"}>
            MetaCollab provides value and service supporting the fourth industrial
            revolution. Web3 is a set of technologies that publicly interoperate
            through blockchain and node based computing systems.
          </Text>
          <Stack
            spacing={4}
            divider={
              <StackDivider
                borderColor={useColorModeValue("gray.100", "gray.700")}
              />
            }
          >
            <Feature
              icon={<Icon as={SunIcon} w={5} h={5} color="blue.500" />}
              iconBg={useColorModeValue("blue.100", "blue.900")}
              text={" Decentralized Autonomous Organizations (DAOs) "}
            />
            <Feature
              icon={<Icon as={AtSignIcon} color={"green.500"} w={5} h={5} />}
              iconBg={useColorModeValue("green.100", "green.900")}
              text={" Team and Enterprise Bridge-Building "}
            />
            <Feature
              icon={<Icon as={SettingsIcon} w={5} h={5} color="purple.500" />}
              iconBg={useColorModeValue("purple.100", "purple.900")}
              text={" Web3 Technologies and Infrastructure "}
            />
          </Stack>
        </Stack>
        <Flex>
          <Image
            objectFit={"center"}
            rounded={"md"}
            src={
              // " https://bafybeih6j2bkbtr3p5ugxb6xvigqqziionmnh6sly2pwrqzbi6zgv3dwki.ipfs.infura-ipfs.io/ "
              // " https://bafybeidg2zafwojw52otuvrbyp4w34rv6gwbijl2fnjlzamu3edqg7thy4.ipfs.infura-ipfs.io/ "
              // " https://bafybeiexrfhhdlx3ric5v7yjez6pfexp4vhpszoq4l5ywdsg4mav4ifjcq.ipfs.infura-ipfs.io/ "
              // " https://bafybeihyswfympur6dq4qswdfq5kwb6e5a2wmi4t4gvxbmxksxhkyc4mhy.ipfs.infura-ipfs.io/ "
              // " https://bafybeigemdjp2j24wyqljbwc7nisg5l2m4h3r5ob5sa4eq4quml4egl3ae.ipfs.infura-ipfs.io/ "
              // " https://bafybeih6j2bkbtr3p5ugxb6xvigqqziionmnh6sly2pwrqzbi6zgv3dwki.ipfs.infura-ipfs.io/ "
              // " https://bafybeidg2zafwojw52otuvrbyp4w34rv6gwbijl2fnjlzamu3edqg7thy4.ipfs.infura-ipfs.io/ "
              // " https://bafybeiexrfhhdlx3ric5v7yjez6pfexp4vhpszoq4l5ywdsg4mav4ifjcq.ipfs.infura-ipfs.io/ "
              // " https://bafybeia5nuk33s2e7szg2wb6fewqs4xmae3r2r7yd27ouoiaymhz3m2j6y.ipfs.infura-ipfs.io/ "
              // " https://bafybeihhmhgalqytd5ntpysjqwlgo56wvvb7r6rcuuj2x3r6l3aqnjf25m.ipfs.infura-ipfs.io/ "
              // " https://bafybeid2lgmvtnxcz7ivszvxne5jfabpcy62ff6k5t3jracs46gwpvag2m.ipfs.infura-ipfs.io/ "
              // " https://bafybeiawt6vzzsvjjfpzsggjkt5s2gtapn5lg3gl67xxdis25kzv7frkk4.ipfs.infura-ipfs.io/ "
              // " https://bafybeiakbeu5a6g4jhrvurbamc75q6d2yoo23ihjguk7va4bdnvdfa5bhe.ipfs.infura-ipfs.io/ "
              // " https://bafybeig6ya4h6ozshcm5tcad5i2aklumdhokzobs4l3xjq7q4gwvg5idcu.ipfs.infura-ipfs.io/ "
              // " https://bafybeicrnln2gm2a5ivbeeepdupdeg33gzzboxw3smo3ssgtx2xoxrrfoe.ipfs.infura-ipfs.io/ "
              // " https://bafybeicjsmb535kxrkwdsfqm7oruiionzf2yvhwl2fxhwvf2j756zkfwcq.ipfs.infura-ipfs.io/ "
              // " https://bafybeidqzoh3flsxoyy264653ctvvero6k52eyrlp6v2qhpk57dacfktb4.ipfs.infura-ipfs.io/ "
              // " https://bafybeifqq4uwoiw2dc5cqimpic3wjp5zhm6uyyuxmspuvvh4kvu74dlyta.ipfs.infura-ipfs.io/ "
              // " https://bafybeieifivymmpggeju24nw2si7uumcxzarrjjx6l25dc4eaaupuwslzu.ipfs.infura-ipfs.io/"
              // " https://bafybeigtzrprfalmfefukvqlwholo4mmjerqavicy6v23z5coyq2wneskm.ipfs.infura-ipfs.io/"
              // " https://bafybeibr57ioe2q33kiqko4od2ecih624vxzjn75bmi7hcxpetdvff276e.ipfs.infura-ipfs.io/"
              // " https://bafybeih2yx3lez53gt3iswcavitk5qdjvz542dnohtf6pbouwrxwhq5dme.ipfs.infura-ipfs.io/"
              // " https://bafybeigycp4qkbhpgk2zv3gvrnmweajn57e6lp4eknvb4ehjiukguxisci.ipfs.infura-ipfs.io/"
              // " https://bafybeiheu5fgk7jcfn6k4lywo2prjzo3fajvswvxjtce6nc65a4f3asqta.ipfs.infura-ipfs.io/"
              // " https://bafybeibtoxoa5un4zs5cfvhutz4axcdv5alolrjau6asyltmczro7wej2y.ipfs.infura-ipfs.io/"
              // " https://bafybeiewni54ty7y56jgak2ke7vdnuioibwtqer5uu5bboxcwp7xnyh4wa.ipfs.infura-ipfs.io/"
              " https://bafybeigaxfpv47tsk7h47nlqbwzxajjfhc4xkxk2tjz3aobvhe5xgjrwve.ipfs.infura-ipfs.io/"
              // " https://bafybeie56rvgxfi6fs7yxqckqtye66fbprf7sgf3uo4rrsirwq2iobaumi.ipfs.infura-ipfs.io/"
              // " https://bafybeicwlscnwijdnuc4j67bsrdnwigozqbvc5tmhlr23tol67sm3xdade.ipfs.infura-ipfs.io/"
              // " https://bafybeigpvim4tjkw6mwaddpymft46grbswwzf3tkmskkznkfdus3udtgum.ipfs.infura-ipfs.io/"
              // " https://bafybeia2thakmxvj2fpiaaloz733otuiewttonur4opufycnltcqnc4m3m.ipfs.infura-ipfs.io/"
                  }
              alt=" MetaCollab "
            
          />
        </Flex>
      </SimpleGrid>
    </Container>
    </ div>

  );
}
export default Feature5;
