import { ReactNode } from "react";
import {
  Box,
  Divider,
  Stack,
  HStack,
  Heading,
  // Image,
  Link,
  Text,
  VStack,
  useColorModeValue,
  List,
  ListItem,
  ListIcon,
  Button,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";

import { FaCheckCircle } from "react-icons/fa";
// import background from "./images/mg-bckg-01-1280w.png";
// import background from "./images/mg-bckg-01-400w.png";
// import background from "./images/mg-bckg-01-600w.png";
// import background from "./images/mg-bckg-01-800w.png";
// import background from "./images/mg-bckg-02-1280w.png";
// import background from "./images/mg-bckg-02-btp-1280w.png";
// import background from "./images/mg-bckg-02-btp-1280x720.png";
// import background from "./images/mg-bckg-02-btp-1280x800.png";
// import background from "./images/mg-bckg-02-ptb-1280w.png";
// import background from "./images/mg-bckg-02-ptb-1280x720.png";
// import background from "./images/mg-bckg-02-ptb-1280x800.png";
// import background from "./images/mg-bckg-02r-1280w.png";
// import background from "./images/mg-bckg-03-1280w.png";
// import background from "./images/mg-bckg-03-btp-1280w.png";
// import background from "./images/mg-bckg-03-btp-1280x720.png";
// import background from "./images/mg-bckg-03-ptb-1280w.png";
// import background from "./images/mg-bckg-03-ptb-1280x720.png";
// import background from "./images/mg-bckg-03r-1280w.png";
// import background from "./images/mg-bckg-04-1280w.png";
// import background from "./images/mg-bckg-04-btp-1280w.png";
// import background from "./images/mg-bckg-04-btp-1280x720.png";
// import background from "./images/mg-bckg-04-ptb-1280w.png";
// import background from "./images/mg-bckg-04-ptb-1280x720.png";
// import background from "./images/mg-bckg-04r-1280w.png";
// import background from "./images/mg-bckg-05-1280w.png";
// import background from "./images/mg-bckg-06-1280w.png";
// import background from "./images/mg-bckg-06-b1-1280w.png";
// import background from "./images/mg-bckg-06-b2-1280w.png";
// import background from "./images/mg-bckg-06c-1280w.png";
// import background from "./images/mg-bckg-06c-btp-1280w.png";
// import background from "./images/mg-bckg-06c-btp-1280x720.png";
// import background from "./images/mg-bckg-06cr-1280w.png";
// import background from "./images/mg-bckg-07-1280w.png";
// import background from "./images/mg-bckg-08-1280w.png";
// import background from "./images/mg-bckg-09-1280w.png";
// import background from "./images/mg-bckg-09-600w.png";
// import background from "./images/mg-bckg-09a-1280w.png";
// import background from "./images/mg-bckg-09b-1280w.png";
// import background from "./images/mg-bckg-09c-1280w.png";
// import background from "./images/mg-flag-01-200w.png";
// import background from "./images/mg-flag-01.svg";
// import background from "./images/mg-gear-logo-200w.png";
// import background from "./images/mg-gear-logo.svg";
// import background from "./images/mg-hero-01-200w.png";
// import background from "./images/mg-hero-02-200w.png";
// import background from "./images/mg-hero-03-200w.png";
// import background from "./images/mg-hero-04-1000sq.png";
// import background from "./images/mg-hero-04-1200w.png";
// import background from "./images/mg-hero-04-800sq.png";
// import background from "./images/mg-hero-05-200w.png";
// import background from "./images/mg-hero-05-300w.png";
// import background from "./images/mg-hero-05-800w.png";
// import background from "./images/mg-hero-06-1200w.png";
// import background from "./images/mg-hero-07-1200w.png";
// import background from "./images/mg-hero-08-1200w.png";
// import background from "./images/mg-hero-09-1200w.png";
// import background from "./images/mg-hero-10-1200w.png";
// import background from "./images/mg-hero-10-200sq.png";
// import background from "./images/mg-hero-10-200w.png";
// import background from "./images/mg-icon-01-200w.png";
// import background from "./images/mg-icon-02-200w.png";
// import background from "./images/mg-icon-03-200w.png";
// import background from "./images/mg-icon-04-200w.png";
// import background from "./images/mg-icon-04a-200w.png";
// import background from "./images/mg-icon-05-200w.png";
// import background from "./images/mg-icon-06-200w.png";
// import background from "./images/mg-logo-01-200w.png";
// import background from "./images/mg-logo-02-200w.png";
// import background from "./images/mg-profile-2022-200w.png";
// import background from "./images/mg-profile-players-200w.png";
// import background from "./images/mg-bckg-12-1280w.png";
// import background from "./images/mg-bckg-12-1280x360.png";
// import background from "./images/mg-bckg-12-1280x1440.png";
import background from "./images/mg-bckg-12-1280w.png";
// import background from "./images/mg-bckg-12-1280x360.png";
// import background from "./images/mg-bckg-12-1280x1440.png";

function PriceWrapper({ children }: { children: ReactNode }) {
  return (
    <Box
      mb={4}
      shadow="base"
      borderWidth="1px"
      alignSelf={{ base: "center", lg: "flex-start" }}
      borderColor={useColorModeValue("gray.200", "gray.500")}
      borderRadius={"xl"}
    >
      {children}
    </Box>
  );
}

function PriceWrapper1({ children }: { children: ReactNode }) {
  return (
    <Box
      mb={4}
      shadow="base"
      borderWidth="1px"
      alignSelf={{ base: "center", lg: "flex-start" }}
      borderColor={useColorModeValue("gray.200", "gray.500")}
      borderRadius={"xl"}
    >
      {children}
    </Box>
  );
}

function Pricing1() {
  return (
    <div style={{ backgroundImage: `url(${background})` }}>
      <Box py={12}>
        <VStack spacing={2} textAlign="center">
          <Heading color="gray.100" as="h1" fontSize="4xl">
            Gig Resolvers set their own terms.
          </Heading>
          <Text fontSize="lg" color={"gray.500"}>
            Requires an electronic signature from both parties.
          </Text>
        </VStack>

        <Divider marginTop="5" />

        <Wrap spacing="30px" ml="20px" marginTop="5">
          <WrapItem width={{ base: "100%", sm: "85%", md: "65%", lg: "50%" }}>
            <Box py={4} px={12}>
              <HStack
                direction={{ base: "column", md: "row" }}
                textAlign="center"
                justify="center"
                spacing={{ base: 4, lg: 10 }}
                py={10}
              >
                <PriceWrapper1>
                  <Box py={4} px={12}>
                    <Text fontWeight="500" fontSize="2xl">
                      Simple Resolver
                    </Text>
                    <HStack justifyContent="center">
                      <Text fontSize="5xl" fontWeight="900">
                        100
                      </Text>
                      <Text fontSize="3xl" color="cyan" fontWeight="600">
                        MATIC
                      </Text>
                      <Text fontSize="3xl" color="gray.100">
                        /resolution
                      </Text>
                    </HStack>
                  </Box>
                  <VStack
                    bg={useColorModeValue("gray.600", "gray.700")}
                    py={4}
                    borderBottomRadius={"xl"}
                  >
                    <List
                      spacing={3}
                      textAlign="start"
                      color="gray.200"
                      px={12}
                    >
                      <ListItem>
                        <ListIcon as={FaCheckCircle} color="green.500" />
                        Unlimited Blockchain Interactions
                      </ListItem>
                      <ListItem>
                        <ListIcon as={FaCheckCircle} color="green.500" />
                        MetaCollab provides value and service supporting the
                        fourth industrial revolution. Web3 is a set of
                        technologies that publicly interoperate through
                        blockchain and node based computing systems.
                      </ListItem>
                      <ListItem>
                        <ListIcon as={FaCheckCircle} color="green.500" />
                        MetaGame Pricing Matrix
                      </ListItem>
                    </List>
                    <Box w="80%" pt={7}>
                      <Button w="full" colorScheme="blue" variant="outline">
                        <Link
                          href=" https://buildagig.metacollab.freeweb3.com "
                          target="_blank"
                        >
                          Interact with MetaGame Now
                        </Link>
                      </Button>
                    </Box>
                  </VStack>
                </PriceWrapper1>

                <PriceWrapper1>
                  <Box position="relative">
                    <Box
                      position="absolute"
                      top="-16px"
                      left="50%"
                      style={{ transform: "translate(-50%)" }}
                    >
                      <Text
                        textTransform="uppercase"
                        bg={useColorModeValue("orange", "orange.700")}
                        px={3}
                        py={1}
                        color={useColorModeValue("white", "gray.300")}
                        fontSize="sm"
                        fontWeight="600"
                        rounded="xl"
                      >
                        Most Popular
                      </Text>
                    </Box>
                    <Box py={4} px={12}>
                      <Text fontWeight="500" fontSize="2xl">
                        Half-day Resolution
                      </Text>
                      <HStack justifyContent="center">
                        <Text fontSize="5xl" fontWeight="900">
                          1,500
                        </Text>
                        <Text fontSize="3xl" color="cyan" fontWeight="600">
                          MATIC
                        </Text>
                        <Text fontSize="3xl" color="yellow.100">
                          /resolution
                        </Text>
                      </HStack>
                    </Box>

                    <VStack
                      bg={useColorModeValue("gray.600", "gray.700")}
                      py={4}
                      borderBottomRadius={"xl"}
                    >
                      <List
                        spacing={3}
                        textAlign="start"
                        color="gray.200"
                        px={12}
                      >
                        <ListItem>
                          <ListIcon as={FaCheckCircle} color="green.500" />
                          Unlimited Blockchain Interactions
                        </ListItem>
                        <ListItem>
                          <ListIcon as={FaCheckCircle} color="green.500" />
                          MetaCollab provides value and service supporting the
                          fourth industrial revolution. Web3 is a set of
                          technologies that publicly interoperate through
                          blockchain and node based computing systems.
                        </ListItem>
                        <ListItem>
                          <ListIcon as={FaCheckCircle} color="green.500" />
                          MetaGame Pricing Matrix
                        </ListItem>
                      </List>
                      <Box w="80%" pt={7}>
                        <Button w="full" colorScheme="blue">
                          <Link
                            href=" https://buildagig.metacollab.freeweb3.com "
                            target="_blank"
                          >
                            Interact with MetaGame Now
                          </Link>
                        </Button>
                      </Box>
                    </VStack>
                  </Box>
                </PriceWrapper1>

                <PriceWrapper1>
                  <Box py={4} px={12}>
                    <Text fontWeight="500" fontSize="2xl">
                      25-hour Resolution
                    </Text>
                    <HStack justifyContent="center">
                      <Text fontSize="5xl" fontWeight="900">
                        5,000
                      </Text>
                      <Text fontSize="3xl" color="cyan" fontWeight="600">
                        MATIC
                      </Text>
                      <Text fontSize="3xl" color="gray.100">
                        /resolution
                      </Text>
                    </HStack>
                  </Box>

                  <VStack
                    bg={useColorModeValue("gray.600", "gray.700")}
                    py={4}
                    borderBottomRadius={"xl"}
                  >
                    <List
                      spacing={3}
                      textAlign="start"
                      color="gray.100"
                      px={12}
                    >
                      <ListItem>
                        <ListIcon as={FaCheckCircle} color="green.500" />
                        Unlimited Blockchain Interactions
                      </ListItem>
                      <ListItem>
                        <ListIcon as={FaCheckCircle} color="green.500" />
                        MetaCollab provides value and service supporting the
                        fourth industrial revolution. Web3 is a set of
                        technologies that publicly interoperate through
                        blockchain and node based computing systems.
                      </ListItem>
                      <ListItem>
                        <ListIcon as={FaCheckCircle} color="green.500" />
                        MetaGame Pricing Matrix
                      </ListItem>
                    </List>
                    <Box w="80%" pt={7}>
                      <Button w="full" colorScheme="blue" variant="outline">
                        <Link
                          href=" https://buildagig.metacollab.freeweb3.com "
                          target="_blank"
                        >
                          Interact with MetaGame Now
                        </Link>
                      </Button>
                    </Box>
                  </VStack>
                </PriceWrapper1>
              </HStack>
            </Box>
          </WrapItem>
        </Wrap>

        <Stack
          direction={{ base: "column", md: "row" }}
          textAlign="center"
          justify="center"
          spacing={{ base: 4, lg: 10 }}
          py={10}
        >
          <PriceWrapper>
            <Box py={4} px={12}>
              <Text fontWeight="500" fontSize="2xl">
                Simple Resolver
              </Text>
              <HStack justifyContent="center">
                <Text fontSize="5xl" fontWeight="900">
                  100
                </Text>
                <Text fontSize="3xl" color="cyan" fontWeight="600">
                  MATIC
                </Text>
                <Text fontSize="3xl" color="gray.100">
                  /resolution
                </Text>
              </HStack>
            </Box>
            <VStack
              bg={useColorModeValue("gray.600", "gray.700")}
              py={4}
              borderBottomRadius={"xl"}
            >
              <List spacing={3} textAlign="start" color="gray.200" px={12}>
                <ListItem>
                  <ListIcon as={FaCheckCircle} color="green.500" />
                  Unlimited Blockchain Interactions
                </ListItem>
                <ListItem>
                  <ListIcon as={FaCheckCircle} color="green.500" />
                  MetaCollab provides value and service supporting the fourth
                  industrial revolution. Web3 is a set of technologies that
                  publicly interoperate through blockchain and node based
                  computing systems.
                </ListItem>
                <ListItem>
                  <ListIcon as={FaCheckCircle} color="green.500" />
                  MetaGame Pricing Matrix
                </ListItem>
              </List>
              <Box w="80%" pt={7}>
                <Button w="full" colorScheme="blue" variant="outline">
                  <Link
                    href=" https://buildagig.metacollab.freeweb3.com "
                    target="_blank"
                  >
                    Interact with MetaGame Now
                  </Link>
                </Button>
              </Box>
            </VStack>
          </PriceWrapper>

          <PriceWrapper>
            <Box position="relative">
              <Box
                position="absolute"
                top="-16px"
                left="50%"
                style={{ transform: "translate(-50%)" }}
              >
                <Text
                  textTransform="uppercase"
                  bg={useColorModeValue("orange", "orange.700")}
                  px={3}
                  py={1}
                  color={useColorModeValue("white", "gray.300")}
                  fontSize="sm"
                  fontWeight="600"
                  rounded="xl"
                >
                  Most Popular
                </Text>
              </Box>
              <Box py={4} px={12}>
                <Text fontWeight="500" fontSize="2xl">
                  Half-day Resolution
                </Text>
                <HStack justifyContent="center">
                  <Text fontSize="5xl" fontWeight="900">
                    1,500
                  </Text>
                  <Text fontSize="3xl" color="cyan" fontWeight="600">
                    MATIC
                  </Text>
                  <Text fontSize="3xl" color="yellow.100">
                    /resolution
                  </Text>
                </HStack>
              </Box>

              <VStack
                bg={useColorModeValue("gray.600", "gray.700")}
                py={4}
                borderBottomRadius={"xl"}
              >
                <List spacing={3} textAlign="start" color="gray.200" px={12}>
                  <ListItem>
                    <ListIcon as={FaCheckCircle} color="green.500" />
                    Unlimited Blockchain Interactions
                  </ListItem>
                  <ListItem>
                    <ListIcon as={FaCheckCircle} color="green.500" />
                    MetaCollab provides value and service supporting the fourth
                    industrial revolution. Web3 is a set of technologies that
                    publicly interoperate through blockchain and node based
                    computing systems.
                  </ListItem>
                  <ListItem>
                    <ListIcon as={FaCheckCircle} color="green.500" />
                    MetaGame Pricing Matrix
                  </ListItem>
                </List>
                <Box w="80%" pt={7}>
                  <Button w="full" colorScheme="blue">
                    <Link
                      href=" https://buildagig.metacollab.freeweb3.com "
                      target="_blank"
                    >
                      Interact with MetaGame Now
                    </Link>
                  </Button>
                </Box>
              </VStack>
            </Box>
          </PriceWrapper>

          <PriceWrapper>
            <Box py={4} px={12}>
              <Text fontWeight="500" fontSize="2xl">
                25-hour Resolution
              </Text>
              <HStack justifyContent="center">
                <Text fontSize="5xl" fontWeight="900">
                  5,000
                </Text>
                <Text fontSize="3xl" color="cyan" fontWeight="600">
                  MATIC
                </Text>
                <Text fontSize="3xl" color="gray.100">
                  /resolution
                </Text>
              </HStack>
            </Box>

            <VStack
              bg={useColorModeValue("gray.600", "gray.700")}
              py={4}
              borderBottomRadius={"xl"}
            >
              <List spacing={3} textAlign="start" color="gray.100" px={12}>
                <ListItem>
                  <ListIcon as={FaCheckCircle} color="green.500" />
                  Unlimited Blockchain Interactions
                </ListItem>
                <ListItem>
                  <ListIcon as={FaCheckCircle} color="green.500" />
                  MetaCollab provides value and service supporting the fourth
                  industrial revolution. Web3 is a set of technologies that
                  publicly interoperate through blockchain and node based
                  computing systems.
                </ListItem>
                <ListItem>
                  <ListIcon as={FaCheckCircle} color="green.500" />
                  MetaGame Pricing Matrix
                </ListItem>
              </List>
              <Box w="80%" pt={7}>
                <Button w="full" colorScheme="blue" variant="outline">
                  <Link
                    href=" https://buildagig.metacollab.freeweb3.com "
                    target="_blank"
                  >
                    Interact with MetaGame Now
                  </Link>
                </Button>
              </Box>
            </VStack>
          </PriceWrapper>
        </Stack>
      </Box>
    </div>
  );
}
export default Pricing1;
